import axios from 'axios'

export const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

instance.interceptors.request.use(function (config) {
  console.log('发起请求')
  return config
}, function (error) {
  return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
  if (response.data.code === '0') {
    return response.data.data
  } else {
    return Promise.reject(response.data)
  }
}, function (error) {
  return Promise.reject(error)
})

export function api (url, params) {
  return instance.post(url, params)
}

export function apiGet (url, params) {
  return instance.get(url, params)
}
